import { useState, useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import play_icon from "../../../../Assets/Icons for pages/play_icon.png";
import { ImPause } from "react-icons/im";
import { useEffect } from "react";
import { API_URL } from "../../../../Apis/config";

const NoteTable = ({ remarks, audioUrl }) => {
  console.log('audioUrl', audioUrl);
  
  //const audioUrl ="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  // const audio = new Audio();

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = audioUrl;
    }
  }, [audioUrl]);

  const toggleAudio = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(
        API_URL.baseUrl + API_URL.OrderAudio + audioUrl
      );
    }

    if (isPlaying) {
      // audio.pause();
      audioRef.current.pause();
    } else {
      audioRef.current.play();
      // audio.play();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <Box
      width="100%"
      boxShadow="3px 3px 7px 0px #00000040"
      sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
    >
      {/*Header*/}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFAB30",
          height: "2.370rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
          Note
        </Typography>
      </Box>

      {/*Rows*/}
      <Stack direction="row" bgcolor="white">
        <Box
          sx={{
            bgcolor: "#EFEFEF",
            height: "3.1rem",
            width: "8.8vw",
            pl: "0.8rem",
            paddingY: "0.4rem",
            borderBottom: "1px solid white",
          }}
        >
          <Typography fontSize="13px">Remarks</Typography>
        </Box>

        <Box
          sx={{
            height: "3.1rem",
            width: "75vw",
            pl: "0.8rem",
            paddingY: "0.4rem",
            bgcolor: "white",
            borderBottom: "1px solid #EFEFEF",
          }}
        >
          <Typography fontSize="13px" fontWeight="500">
            {remarks}
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction="row"
        bgcolor="white"
        sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
      >
        <Box
          sx={{
            bgcolor: "#EFEFEF",
            width: "8.8vw",
            pl: "0.8rem",
            paddingY: "0.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography fontSize="13px">Voice Note</Typography>
        </Box>

        <Box
          sx={{
            width: "75vw",
            pl: "0.8rem",
            paddingY: "0.5rem",
            bgcolor: "white",
            borderBottomRightRadius: "5px",
          }}
        >
          {audioUrl ? (
            <button
              onClick={toggleAudio}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
              }}
            >
              {isPlaying ? (
                <ImPause fontSize="16px" />
              ) : (
                <img
                  src={play_icon}
                  alt=""
                  style={{ width: "16px", objectFit: "fill" }}
                />
              )}
            </button>
          ) : (
            <Typography fontSize="12.4px">Not Available</Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default NoteTable;
