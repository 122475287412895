import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CREATEUSER, GEBYMOBILENO } from "../../../../Apis/booking_order_api";
import { GET_CITIE } from "../../../../Apis/city_api";
import MultiSelection from "../../../../Component/Multiselection/MultiSelection";
import { ToastShow } from "../../../../Component/Toast/Toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import LocationSearchInput from "../../../../Component/AutoComplate/AddressAutoComplate";
import { GETCATEGORY } from "../../../../Apis/category_api";
import Styles from "./createOrder.module.css";
import {
  ADD_SERVICE,
  DELETE_SERVICE,
  GET_SERVICE,
} from "../../../../Apis/service_api";
import PriceHour from "../../../../Component/GroupButton/PriceHour";
import { format } from "date-fns";
import {
  PAY_BY_COIN_WALLET,
  addOrder,
  confirmOrder,
  deleteOrderCouponById,
  getDetailsByGst,
  getOrderDetailsById,
} from "../../../../Apis/order_api";
import { COUPENAPPLYTOORDER } from "../../../../Apis/coupen_api";
import GetCouponDailog from "../../../../Component/Dailog/GetCouponDailog";
import { GET_BALANCE } from "../../../../Apis/users_api";
import { Delete, Launch } from "@mui/icons-material";
import ConfirmationDailog from "../../../../Component/Dailog/ConfirmationDailog";
import TextFieldSmall from "../../../../Component/TextFields/TextFieldSmall";
import RadioButton from "../../../../Component/RadioButton/RadioButton";
import ButtonView from "../../../../Component/Button/ButtonView";
import SelectSmall from "../../../../Component/SelectComponent/SelectSmall";
import DateTime from "../../../../Component/DateTime/DateTime";
import ButtonForGrid from "../../../../Component/Button/ButtonForGrid";
import LoadingOverlay from "react-loading-overlay";
import AddOrderDiscount from "./AddOrderDiscount";

function EditBooking({ data, isDraft }) {
  console.log('data?.remarks', data);
  
  const [remarks, setRemarks] = useState(data?.remarks ?? "");
  console.log('remarks', remarks);
  
  const [mobileNu, setMobileNu] = useState(data.mobile ?? "");
  const [user, setUser] = useState({ id: data.user_id });
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [extraServiceW, setExtraServiceW] = React.useState([]);
  console.log('extraServiceW', extraServiceW);
  
  const [postServiceW, setPostServiceW] = React.useState([]);
  const [serviceW, setServiceW] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([data.city]);
  const [category, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategories] = React.useState(
    data.category_name ? [data.category_name] : []
  );
  const [categoriesW, setCategoriesW] = React.useState([]);
  const [subCategory, setSubCategories] = React.useState([]);
  const [selectedSubCategory, setSelectedSubCategories] = React.useState(
    data?.sub_category_name ? [data.sub_category_name] : []
  );
  const [subCategoriesW, setSubCategoriesW] = React.useState([]);
  const [isUserExist, setUserExist] = useState(1);
  const [canCreateOrder, setCanCreateOrder] = useState(1);
  const [address, setAddress] = React.useState(data.address ?? "");
  const [latLng, setLatLng] = React.useState({
    lat: data.latitude,
    lng: data.longitude,
  });
  const [addressNr, setAddressNr] = React.useState(data.near_by_address ?? "");
  const [addressFlat, setAddressFlat] = React.useState(data.address1 ?? "");
  const [latLngNr, setLatLngNr] = React.useState({});
  const [firstName, setFName] = useState(data.first_name ?? "");
  const [lastName, setLName] = useState("");
  const [middleName, setMName] = useState("");
  const [gender, setGender] = useState(2);
  const [email, setEmail] = useState("");
  const [dateTime, setDateTime] = useState(Date.parse(data.date_time));
  const [gst, setGst] = useState(data.gst_no ?? "");
  const [businessName, setBusinessName] = useState(data.business_name ?? "");
  const [billingAddress, setBillingAddress] = useState(
    data.billing_address ?? ""
  );
  const [someName, setSomeName] = useState(data.someone_name ?? "");
  const [someMobile, setSomeMobile] = useState(data.someone_mobile ?? "");
  const [someOneRadio, setSomeOneRadio] = useState(data.is_for_someone ?? 2);
  const [isPodRadio, setIsPodRadio] = useState(data.is_pod_can_use ?? 2);
  const [shootRadio, setShootRadio] = useState(data.shoot_area ?? 2);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);

  const [subCategoryName, setSubCategoryName] = useState(
    data?.sub_category_name
  );

  const [isCollaboration, setIsCollaboration] = useState(
    data?.isCollab ? true : false
  );
  // const [isRepeatPreviousProvider, setIsRepeatPreviousProvider] = useState(
  //   data?.is_repeat_previous_provider ? true : false
  // );

  const [isRepeatPreviousProvider, setIsRepeatPreviousProvider] =
    useState(false);

  const [isNotify, setIsNotify] = useState(false);
  const [area, setArea] = useState(data?.area);

  const [isCoinRedeem, setIsCoinRedeem] = useState(
    data?.is_coin_redeem ? true : false
  );
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(null);
  const [isCheck, setIsCheck] = useState(false);

  const getUserWalletBalance = async () => {
    const r = await GET_BALANCE(data.user_id);

    if (r && r.s) {
      setBalance(r?.r);
    }
  };

  const handleClose = async (v, code) => {
    if (v == 1 && code) {
      const res = await COUPENAPPLYTOORDER(data.id, code, user.id);
      if (res && res.s) {
        ToastShow("Applied successfully");
        getData();
      } else {
        ToastShow("Oops! something went wrong");
      }
    }

    setOpen(false);
  };

  const submitOrder = async ({ load = 1 }) => {
    
    if (!selectedCities.length) {
      ToastShow("Please select city");
    } else if (selectedCategory.length <= 0) {
      ToastShow("Please select category");
    } else if (!address) {
      ToastShow("Please select address");
    } else if (!addressNr) {
      ToastShow("Please fill near address");
    } else if (gst.length > 0 && businessName.length == 0) {
      ToastShow("Please enter valid GST number");
    } else if (someOneRadio == 1 && (!someName || !someMobile)) {
      ToastShow("Please enter someone details");
    } else {
      if (load) {
        setIsLoading(true);
      } else {
        setIsLoadingOverlay(true);
      }

      // console.log( citiesW.length, selectedCities[0]);
      // console.log( categoriesW.length, selectedCategory[0]);
      // console.log( subCategoriesW.length, selectedSubCategory[0]);

      const c = citiesW.filter((v) => v.name == selectedCities[0]);
      const cat = categoriesW.filter((v) => v.name == selectedCategory[0]);
      const sCat = subCategoriesW.filter(
        (v) => v.name == selectedSubCategory[0]
      );

      // console.log( c.length, JSON.stringify(c));
      // console.log( cat.length, JSON.stringify(cat));
      // console.log( cat.length, JSON.stringify(sCat));
      // console.log( "categoriesW", JSON.stringify(categoriesW));

      if (
        !(c.length && cat.length && (sCat.length || subCategoryName?.length))
      ) {
        ToastShow("Please select the city, category and subcategory");
        setIsLoading(false);
        setIsLoadingOverlay(false);
        await getData();
        return;
      }

      const data_edit = {
        orders_id: data.id,
        remarks: remarks,
        user_id: user.id,
        city_id: c[0].id,
        date_time: format(dateTime, "yyyy-MM-dd HH:mm:ss"),
        shoot_area: shootRadio == 1 ? 1 : 0,
        address: address,
        near_by_address: addressNr,
        latitude: latLng.lat,
        longitude: latLng.lng,
        gst_no: gst,
        address1: addressFlat,
        business_name: businessName,
        billing_address: billingAddress,
        is_for_someone: someOneRadio == 1 ? 1 : 0,
        someone_name: someOneRadio == 1 ? someName : "",
        someone_mobile: someOneRadio == 1 ? someMobile : "",
        is_pod_can_use: isPodRadio == 1 ? 1 : 0,
        category_id: cat[0].id,
        sub_category_id:
          subCategoryName != null || subCategoryName != ""
            ? sCat.length
              ? sCat[0].id
              : 0
            : 0,
        sub_category_name: subCategoryName,
        isCollaboration: isCollaboration,
        isRepeatPreviousProvider: isRepeatPreviousProvider,
        isCoinRedeem: isCoinRedeem,
        area: area,
        isNotify: isNotify ? 1 : 0
      };

      const res = await addOrder(data_edit);

      if (res && res.s) {
        ToastShow(res.m);
        for (const i of serviceW) {
          if (i.checked) {
            const d = {
              orders_id: data.id,
              service_id: i.service_id,
              qty: i.qty ?? 1,
              hours: i.hour ?? 1,
              orders_items_id: i.orders_items_id ?? 0,
              provider_cut: i.cut,
            };

            const dres = await ADD_SERVICE(d);
            if (dres && !dres.s) {
              ToastShow(dres.m);
              setIsLoading(false);
              return;
            }
          } else if (!i.checked && i.orders_items_id) {
            const dres = await DELETE_SERVICE(i.orders_items_id);
            if (dres && !dres.s) {
              ToastShow(dres.m);
              setIsLoading(false);
              return;
            }
          }
        }

        for (const i of extraServiceW) {
          if (i.checked) {
            const d = {
              orders_id: data.id,
              service_id: i.service_id,
              qty: i.qty ?? 1,
              hours: i.hour ?? 1,
              orders_items_id: i.orders_items_id ?? 0,
              remarks: i.remarks,
            };
            const dres = await ADD_SERVICE(d);
            if (dres && !dres.s) {
              ToastShow(dres.m);
              setIsLoading(false);
              return;
            }
          } else if (!i.checked && i.orders_items_id) {
            const dres = await DELETE_SERVICE(i.orders_items_id);
            if (dres && !dres.s) {
              ToastShow(dres.m);
              setIsLoading(false);
              return;
            }
          }
        }

        for (const i of postServiceW) {
          if (i.checked) {
            const d = {
              orders_id: data.id,
              service_id: i.service_id,
              qty: i.qty ?? 1,
              hours: i.hour ?? 1,
              orders_items_id: i.orders_items_id ?? 0,
            };
            const dres = await ADD_SERVICE(d);
            if (dres && !dres.s) {
              ToastShow(dres.m);
              setIsLoading(false);
              return;
            }
          } else if (!i.checked && i.orders_items_id) {
            const dres = await DELETE_SERVICE(i.orders_items_id);
            if (dres && !dres.s) {
              ToastShow(dres.m);
              setIsLoading(false);
              return;
            }
          }
        }
        
        if (load && isDraft) {
          const d = await confirmOrder(data.id, isNotify, dataS?.status);
          if (d && d.s) {
            await getData();
            window.open("/order/all", "_self");
          } else {
            ToastShow(d?.m ?? "Opps! something went wrong.");
          }
        }
        if (isLoading) {
          ToastShow("Order update successfully");
          window.open("/order/all", "_self");
        }
      } else {
        ToastShow(res ? res.m : "Something went wrong wrong.");
      }
      if (!load) {
        await getData();
        setIsLoadingOverlay(false);
      }
    }
    if (load) {
      setIsLoading(false);
    }
  };

  const handleSomeOneRadio = (e) => {
    const v = e.target.value == "Yes" ? 1 : 0;
    setSomeOneRadio(v);
  };

  const handleIsPosRadio = (e) => {
    const v = e.target.value == "Yes" ? 1 : 0;
    setIsPodRadio(v);
  };

  const handleShootRadio = (e) => {
    const v = e.target.value == "Outdoor" ? 1 : 0;
    setShootRadio(v);
  };

  const handleSubCategoryName = (e) => {
    setSelectedSubCategories([]);
    setSubCategoryName(e.target.value);
  };

  const handleGst = async (e) => {
    setGst(e.target.value);

    if (e.target.value.length >= 15) {
      // call gst api
      const d = await getDetailsByGst(e.target.value);
      if (d && d.s) {
        setBusinessName(d.r.name);
        setBillingAddress(d.r.address);
        ToastShow(d?.m);
      } else {
        ToastShow(d?.m);
        setBusinessName("");
        setBillingAddress("");
      }
    } else {
      setBusinessName("");
      setBillingAddress("");
    }
  };

  const handleBusinessName = (e) => {
    setBusinessName(e.target.value);
  };

  const handleBillingAddress = (e) => {
    setBillingAddress(e.target.value);
  };

  const handleSomeName = (e) => {
    setSomeName(e.target.value);
  };
  const handleSomeMobile = (e) => {
    setSomeMobile(e.target.value);
  };

  const handleFName = (e) => {
    setFName(e.target.value);
  };

  const handleMName = (e) => {
    setMName(e.target.value);
  };

  const handleLName = (e) => {
    setLName(e.target.value);
  };

  const handleMobileNum = (e) => {
    setMobileNu(e.target.value);
  };

  const handleGender = (e) => {
    // console.log(e.target);
    setGender(e.target.value == "Male" ? 0 : 1);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleDateTime = (v) => {
    setDateTime(v);
    console.log("Yo we here!");
  };

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    const c = citiesW.filter((v) => v.name == value);
    // console.log("city", c);
    const cat = categoriesW.filter(
      (v) => v.name == (selectedCategory.length ? selectedCategory[0] : "")
    );
    await getService(c.length ? c[0].id : 0, cat.length ? cat[0].id : 0);
  };

  const handleSelectedCategory = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedCategories([value]);
    const d = categoriesW.filter((v) => v.name == value);

    await getSubCategory(d[0].id, 0);

    const cat = categoriesW.filter((v) => v.name == value);
    const c = citiesW.filter(
      (v) => v.name == (selectedCities.length ? selectedCities[0] : "")
    );
    await getService(c.length ? c[0].id : 0, cat.length ? cat[0].id : 0);
  };

  const handleServiceChecked = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = serviceW;
    data[i].checked = v;
    setServiceW([...data]);
    submitOrder({ load: 0 });
  };

  const handleServiceCheckedE = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = extraServiceW;
    data[i].checked = v;
    setExtraServiceW([...data]);
    submitOrder({ load: 0 });
  };

  const handleServiceCheckedP = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = postServiceW;
    data[i].checked = v;
    setPostServiceW([...data]);
    submitOrder({ load: 0 });
  };

  const handleServiceQty = (v, i) => {
    // console.log("qty value", v, "index", i);
    const data = serviceW;
    data[i].qty = v + (data[i].qty ?? 1);
    setServiceW([...data]);
    submitOrder({ load: 0 });
  };

  const handleServiceHour = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = serviceW;
    data[i].hour = v + (data[i].hour ?? 1);
    setServiceW([...data]);
    submitOrder({ load: 0 });
  };

  const handleServiceProviderCut = (v, i) => {
    const data = serviceW;
    if (+v >= 0 && +v <= 100) {
      data[i].cut = v;
      setServiceW([...data]);
    } else {
      data[i].cut = "";
      setServiceW([...data]);
      ToastShow("Please enter cut 0 to 100 %");
    }
  };
  const handleServiceProviderCutT = () => {
    submitOrder({ load: 0 });
  };

  const handleServiceQtyE = (v, i) => {
    // console.log("qty value", v, "index", i);
    const data = extraServiceW;
    data[i].qty = v + (data[i].qty ?? 1);
    setExtraServiceW([...data]);
    submitOrder({ load: 0 });
  };

  const handleServiceHourE = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = extraServiceW;
    data[i].hour = v + (data[i].hour ?? 1);
    setExtraServiceW([...data]);
    submitOrder({ load: 0 });
  };
  // const handleServiceRemark = (v, i) => {
  //   console.log("qty value", v, "index", i);
  //   const data = extraServiceW;
  //   console.log('data', data);
  //   data[i]?.remarks = v;
  //   setExtraServiceW([...data]);
  // };
  const handleServiceRemark = (v, i) => {
    console.log("qty value", v, "index", i);
    const updatedData = extraServiceW.map((item, index) => {
      if (index === i) {
        return { ...item, remarks: v };
      }
      return item;
    });
    console.log('updatedData', updatedData);
    setExtraServiceW(updatedData);
  };
  
  const handleServiceQtyP = (v, i) => {
    // console.log("qty value", v, "index", i);
    const data = postServiceW;
    data[i].qty = v + (data[i].qty ?? 1);
    setPostServiceW([...data]);
    submitOrder({ load: 0 });
  };

  const handleServiceHourP = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = postServiceW;
    data[i].hour = v + (data[i].hour ?? 1);
    setPostServiceW([...data]);
    submitOrder({ load: 0 });
  };

  const handleSubSelectedCategory = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedSubCategories([value]);
    setSubCategoryName("");
  };

  const handleAddress = (address) => {
    setAddress(address);
  };

  const updateLatLng = (latLngs) => {
    setLatLng(latLngs);
  };

  const handleAddressNr = (address) => {
    setAddressNr(address.target.value);
  };

  const handleAddressFlat = (address) => {
    setAddressFlat(address.target.value);
  };

  const updateLatLngNr = (latLngs) => {
    setLatLngNr(latLngs);
  };

  const getUser = async () => {
    if (!mobileNu) {
      ToastShow("Please fill mobile number");
    } else {
      const res = await GEBYMOBILENO(mobileNu);

      if (res && res.s) {
        setUserExist(1);
        setUser(res.r);
        setSelectedCategories([]);
        setSelectedCities([]);
        setSelectedSubCategories([]);
        setAddress("");
        setAddressNr("");
        setAddressFlat("");
        setIsPodRadio(2);
        setShootRadio(2);
        setSomeOneRadio(2);
        setSomeName("");
        setSomeMobile("");
        setServiceW([]);
        setExtraServiceW([]);
        setPostServiceW([]);
        setDateTime(new Date());
        if (res.r.businesses.length) {
          setBusinessName(res.r.businesses[0].business_name);
          setGst(res.r.businesses[0].gst_no);
          setBillingAddress(res.r.businesses[0].billing_address);
        } else {
          setBusinessName("");
          setGst("");
          setBillingAddress("");
        }
        setCanCreateOrder(1);
        ToastShow("Success");
      } else {
        setServiceW([]);
        setExtraServiceW([]);
        setPostServiceW([]);
        setSelectedCategories([]);
        setSelectedCities([]);
        setSelectedSubCategories([]);
        setAddress("");
        setAddressNr("");
        setAddressFlat("");
        setIsPodRadio(2);
        setShootRadio(2);
        setSomeOneRadio(2);
        setSomeName("");
        setSomeMobile("");
        setDateTime(new Date());
        setBusinessName("");
        setGst("");
        setBillingAddress("");
        setUserExist(0);
      }
    }
  };

  const handleUserRag = async () => {
    if (!firstName) {
      ToastShow("Please fill first name");
    } else if (!middleName) {
      ToastShow("Please fill middle name");
    } else if (!lastName) {
      ToastShow("Please fill last name");
    } else if (!email) {
      ToastShow("Please fill email");
    } else if (gender == 2) {
      ToastShow("Please select gender");
    } else {
      const res = await CREATEUSER({
        firstName,
        lastName,
        middleName,
        email,
        mobile: mobileNu,
        gender: gender == 0 ? "Male" : "Female",
      });

      if (res && res.s) {
        setUser(res.r);
        setCanCreateOrder(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
    } else {
      ToastShow(t?.m ?? "Opps Something went wrong please try again.");
    }
  }

  async function getService(cityId = 0, categoryId = 0, check = 0) {
    const t = await GET_SERVICE(cityId, categoryId, 1, 0, 0);
    if (t && t.s == 1) {
      if (t.r) {
        if (check) {
          dataS?.items?.map((val) => {
            t.r.map((v, i) => {
              //   console.log(val.name, v);
              if (val.name == v.name && val.isFixed) {
                t.r[i].qty = val.qty;
                t.r[i].checked = true;
                t.r[i].hour = val.hours;
                t.r[i].orders_items_id = val.id;
                t.r[i].provider_cut = val.provider_cut;
                t.r[i].cut = val.provider_cut;
              }
            });
          });
        }
        setServiceW([...t.r]);
      } else {
        setServiceW([]);
      }
    }

    const e = await GET_SERVICE(cityId, categoryId, 0, 1, 0);
    if (e && e.s == 1) {
      if (e.r) {
        if (check) {
          dataS?.items?.map((val) => {
            e.r.map((v, i) => {
              if (val.name == v.name && val.isExtra) {
                e.r[i].qty = val.qty;
                e.r[i].checked = true;
                e.r[i].hour = val.hours;
                e.r[i].orders_items_id = val.id;
                e.r[i].remarks = val.remarks;
                e.r[i].discounts = val.discounts;
              }
            });
          });
        }

        setExtraServiceW([...e.r]);
      } else {
        setExtraServiceW([]);
      }
    }

    const p = await GET_SERVICE(cityId, categoryId, 0, 0, 1);
    if (p && p.s == 1) {
      if (p.r) {
        if (check) {
          dataS?.items?.map((val) => {
            p.r.map((v, i) => {
              if (val.name == v.name && val.isPost) {
                p.r[i].qty = val.qty;
                p.r[i].checked = true;
                p.r[i].hour = val.hours;
                p.r[i].orders_items_id = val.id;
                p.r[i].discounts = val.discounts;
              }
            });
          });
        }

        setPostServiceW([...p.r]);
      } else {
        setPostServiceW([]);
      }
    }
  }

  async function getCategory(c) {
    const t = await GETCATEGORY();

    if (t && t.s == 1) {
      setCategoriesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCategories([...c]);
    }

    if (data.category_id) {
      getSubCategory(data.category_id, c);
    }
  }

  async function getSubCategory(id, c = 0) {
    if (!c) {
      setSelectedSubCategories([]);
      setSubCategoryName("");
    }

    const t = await GETCATEGORY(id);

    if (t && t.s == 1) {
      if (t.r) {
        setSubCategoriesW([...t.r]);
        const c = [];
        for (const v of t.r) {
          c.push(v.name);
        }
        setSubCategories([...c]);
      } else {
        setSubCategoriesW([]);
        setSubCategories([]);
      }
    } else {
      ToastShow(t?.m ?? "Opps Something went wrong please try again.");
    }
  }

  const handleRemarkName = (e) => {
    setRemarks(e.target.value);
  };

  useEffect(() => {
    getCities();
    getCategory(1);
    getService(data.city_id ?? 0, data.category_id ?? 0, 1);
  }, []);

  const [dataS, setDataS] = useState(null);

  useEffect(() => {
    getData();
    getUserWalletBalance();
  }, []);

  useEffect(() => {
    if (dataS) {
      // console.log("call")
      // const c = citiesW.filter((v) => v.name == selectedCities[0]);
      // const cat = categoriesW.filter((v) => v.name == selectedCategory[0]);
      getService(dataS.city_id ?? 0, dataS.category_id ?? 0, 1);
    }
  }, [dataS]);

  const getData = async () => {
    const dataS = await getOrderDetailsById(data.id);
    setDataS(dataS.r);
  };

  const [openDeleteCoupon, setOpenDeleteCoupon] = useState(false);

  const deleteCoupon = async (v) => {
    setOpenDeleteCoupon(false);

    if (v) {
      const d = await deleteOrderCouponById(data.id);

      if (d && d.s) {
        ToastShow("Success");
        getData();
      } else {
        ToastShow(d?.m ?? "Opps! something went wrong. Please try again.");
      }
    }
  };

  const updateArea = (results) => {
    try {
      const r = results[0].address_components
        .filter(
          (v) => v.types.includes("postal_code") || v.types.includes("locality")
        )
        .map((v) => v.long_name)
        .join(",");
      setArea(r ?? "");
    } catch (e) {}
  };

  const payByCoinWallet = async (userID) => {
    setIsLoading(true);
    const res = await PAY_BY_COIN_WALLET({ userID, orderID: data?.id });
    if (res && res.s) {
      ToastShow(res.m);
      window.open("/order/all", "_self");
    } else {
      ToastShow(res.m);
    }
    setIsLoading(false);
  };

  return (
    <LoadingOverlay
      active={isLoadingOverlay}
      spinner
      text="Loading your content..."
    >
      {/*  AUTHENTICATION */}
      <Authentication
        handleMobileNum={handleMobileNum}
        mobileNu={mobileNu}
        getUser={getUser}
        canCreateOrder={canCreateOrder}
        isDraft={isDraft}
      />
      <Divider />
      {/* USER REGISTRATION */}

      <Box
        sx={{
          width: "100%",

          boxShadow: "5px 2px 8px 0px #00000040",
          bgcolor: "white",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          p: "1rem",
          mt: "2rem",
        }}
      >
        <UserRagistration
          isUserExist={isUserExist}
          lastName={lastName}
          firstName={firstName}
          middleName={middleName}
          handleFName={handleFName}
          handleLName={handleLName}
          handleMName={handleMName}
          handleUserRag={handleUserRag}
          handleGender={handleGender}
          gender={gender}
          email={email}
          handleEmail={handleEmail}
          canCreateOrder={canCreateOrder}
        />

        <Divider />

        {/* CREATE ORDER */}
        {canCreateOrder ? (
          <CreateOrderView
            balance={balance}
            isCoinWalletChecked={isCheck}
            getData={getData}
            isDraft={isDraft}
            handleRemarkName={handleRemarkName}
            handleServiceRemark={handleServiceRemark}
            remarks={remarks}
            status={data.status}
            selectedCities={selectedCities}
            handleSelectCity={handleSelectCity}
            cities={cities}
            dateTime={dateTime}
            handleDateTime={handleDateTime}
            address={address}
            handleAddress={handleAddress}
            updateLatLng={updateLatLng}
            addressNr={addressNr}
            addressFlat={addressFlat}
            handleAddressNr={handleAddressNr}
            handleAddressFlat={handleAddressFlat}
            updateLatLngNr={updateLatLngNr}
            selectedCategory={selectedCategory}
            handleSelectCategory={handleSelectedCategory}
            selectedSubCategory={selectedSubCategory}
            handleSubCategory={handleSubSelectedCategory}
            category={category}
            subCategory={subCategory}
            handleGst={handleGst}
            gst={gst}
            handleBusinessName={handleBusinessName}
            businessName={businessName}
            handleBillingAddress={handleBillingAddress}
            billingAddress={billingAddress}
            handleSomeName={handleSomeName}
            handleSomeMobile={handleSomeMobile}
            someName={someName}
            someMobile={someMobile}
            services={serviceW}
            servicesE={extraServiceW}
            servicesP={postServiceW}
            handleServiceChecked={handleServiceChecked}
            handleServiceQty={handleServiceQty}
            handleServiceHour={handleServiceHour}
            handleServiceProviderCut={handleServiceProviderCut}
            handleServiceProviderCutT={handleServiceProviderCutT}
            handleServiceCheckedE={handleServiceCheckedE}
            handleServiceQtyE={handleServiceQtyE}
            handleServiceHourE={handleServiceHourE}
            handleServiceCheckedP={handleServiceCheckedP}
            handleServiceQtyP={handleServiceQtyP}
            handleServiceHourP={handleServiceHourP}
            someOneRadio={someOneRadio}
            handleSomeOneRadio={handleSomeOneRadio}
            isPodRadio={isPodRadio}
            handleIsPosRadio={handleIsPosRadio}
            shootRadio={shootRadio}
            handleShootRadio={handleShootRadio}
            submitOrder={submitOrder}
            subCategoryName={subCategoryName}
            handleSubCategoryName={handleSubCategoryName}
            isCollaboration={isCollaboration}
            updateArea={updateArea}
            area={area}
            dataS={dataS}
          >
            {canCreateOrder ? (
              <>
                <Box>
                  <FormControlLabel
                    label="Is Collaboration ?"
                    control={
                      <Checkbox
                        checked={isCollaboration}
                        onChange={(e) => {
                          setIsCollaboration(e.target.checked);
                          if (!e.target.checked) {
                            const data = serviceW;
                            const d = data.map((v) => ({
                              ...v,
                              cut: 0,
                            }));
                            setServiceW([...d]);
                          }
                        }}
                      />
                    }
                  />
                </Box>
                {/* <FormControlLabel
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  label="Is Repeat Previous Provider ?"
                  control={
                    <Checkbox
                      checked={isRepeatPreviousProvider}
                      onChange={(e) => {
                        setIsRepeatPreviousProvider(e.target.checked);
                      }}
                    />
                  }
                /> */}

                {dataS?.status == 0 && (
                  <Box>
                    <FormControlLabel
                      label="Is Notify ?"
                      control={
                        <Checkbox
                          checked={isNotify}
                          onChange={(e) => setIsNotify(e.target.checked)}
                        />
                      }
                    />
                  </Box>
                )}

                {balance && balance.coin ? (
                  <Box>
                    <FormControlLabel
                      label="Is Coin Redeem ?"
                      control={
                        <Checkbox
                          checked={isCoinRedeem}
                          onChange={(e) => setIsCoinRedeem(e.target.checked)}
                        />
                      }
                    />
                  </Box>
                ) : null}
              </>
            ) : null}

            {balance?.coin_refunded > 0 ? (
              <FormControlLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                label={`Make payment by Coin Wallet (Available: ${balance?.coin_refunded})`}
                control={
                  <Checkbox
                    checked={isCheck}
                    onChange={(e) => setIsCheck(e.target.checked)}
                  />
                }
              />
            ) : null}

            {canCreateOrder ? (
              isLoading ? (
                <CircularProgress />
              ) : (
                <ButtonView
                  lable={(isDraft ? "Confirmed" : "Update") + " Order"}
                  sx={{ width: "14rem", height: "2.170rem" }}
                  fontSize="14px"
                  fontWeight="500"
                  onClick={() => {
                    isCheck
                      ? payByCoinWallet(balance.user_id)
                      : submitOrder({ load: 1 });
                  }}
                />
              )
            ) : null}
          </CreateOrderView>
        ) : null}

        {canCreateOrder && (
          <Box
            sx={{
              my: 3,
              mb: 0,
              mr: 8,
              alignSelf: "end",
            }}
          >
            <ButtonView
              lable="Want to apply coupon"
              sx={{ width: "14rem", height: "2.170rem" }}
              fontSize="14px"
              fontWeight="500"
              onClick={() => setOpen(true)}
            />
          </Box>
        )}

        {canCreateOrder && dataS && dataS.coupon_id > 0 && dataS.coupon && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "96%",
              mt: 1,
            }}
          >
            <Typography sx={{ mr: 1, fontWeight: "bold" }}>
              Applied Coupon
            </Typography>
            {dataS.coupon_id > 0 && dataS.coupon ? (
              <Chip
                label={dataS.coupon?.code}
                deleteIcon={<Delete />}
                onDelete={() => setOpenDeleteCoupon(true)}
              />
            ) : null}
          </Box>
        )}

        {/* <Box>
            {dataS && (
              <Box>
                {dataS.charges.map((v) => {
                  return (
                    <Box
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "start",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {v.name} :{" "}
                        </Typography>

                        <Typography sx={{ ml: 2 }}>
                          {" "}
                          ₹{v.amount.toFixed(2)}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle">
                        {v.description}
                      </Typography>
                    </Box>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Subtoal :{" "}
                  </Typography>
                  <Typography sx={{ ml: 2 }}>
                    {" "}
                    ₹{dataS.sub_total.toFixed(2)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Total Amount :{" "}
                  </Typography>
                  <Typography sx={{ ml: 2 }}>
                    {" "}
                    ₹{dataS.total_amount.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box> */}
      </Box>

      {open && (
        <GetCouponDailog
          orderId={data.id}
          open={open}
          handleClose={handleClose}
          cityId={citiesW.filter((v) => v.name == selectedCities[0])[0].id}
          userId={user.id}
        />
      )}

      {openDeleteCoupon && (
        <ConfirmationDailog
          open={openDeleteCoupon}
          handleOnClose={deleteCoupon}
          title="Delete Coupon!"
          content="Are you sure, You want to delete this?"
        />
      )}
    </LoadingOverlay>
  );
}

export default EditBooking;

function Authentication({
  handleMobileNum,
  mobileNu,
  getUser,
  canCreateOrder,
  isDraft,
}) {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "11.7rem",
          boxShadow: "5px 2px 8px 0px #00000040",
          bgcolor: "white",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "97.8%",
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          {/*Upper Texts*/}
          <Box
            height="60px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              style={{
                color: "black",
                fontSize: "1.4rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              Order
            </Typography>
          </Box>

          {/*Enter mobile number*/}
          <Box
            sx={{
              width: "22.5rem",
              height: "82px",
              bgcolor: "#F6F6F6",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "96%",
                display: "flex",
                flexDirection: "column",
                gap: ".3rem",
              }}
            >
              <Typography
                style={{
                  fontSize: "14.2px",
                  color: "#252525",
                }}
              >
                Mobile Number
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  height: "37px",
                  bgcolor: "white",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <input
                  id="mobileNumber"
                  label="Mobile Number"
                  autoFocus
                  className={Styles.in}
                  value={mobileNu}
                  onChange={handleMobileNum}
                  maxLength={15}
                  placeholder="Enter number"
                  disabled
                  style={{
                    width: "76.5%",
                    height: "21px",
                    fontSize: "14.3px",
                    fontWeight: "500",
                    border: "none",
                    outline: "none",
                    color: "black",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function UserRagistration({
  isUserExist,
  firstName,
  lastName,
  middleName,
  handleFName,
  handleLName,
  handleMName,
  handleUserRag,
  handleGender,
  gender,
  email,
  handleEmail,
  canCreateOrder,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "DM Sans",
          fontSize: "18px",
          fontWeight: "500",
        }}
      >
        User
      </Typography>
      <Box
        sx={{
          display: "flex",
          my: 2,
          gap: "10px",
        }}
      >
        <TextFieldSmall
          disabled={true}
          label="First name"
          placeholder="First name"
          name="First name"
          value={firstName}
          onChange={handleFName}
        />
        <TextFieldSmall
          disabled={true}
          label="Middle name"
          placeholder="Middle name"
          name="Middle name"
          value={middleName}
          onChange={handleMName}
        />

        <TextFieldSmall
          disabled={true}
          label="Last name"
          placeholder="Last name"
          name="Last name"
          value={lastName}
          onChange={handleLName}
        />
      </Box>
      {/* {isUserExist == -1 ? null : !isUserExist ? <CreateOrderView/> : null} */}
    </Box>
  );
}

function CreateOrderView({
  balance,
  isCoinWalletChecked,
  getData,
  handleRemarkName,
  handleServiceRemark,
  remarks,
  status,
  selectedCities,
  handleSelectCity,
  cities,
  dateTime,
  handleDateTime,
  address,
  handleAddress,
  updateLatLng,
  addressNr,
  addressFlat,
  handleAddressNr,
  handleAddressFlat,
  updateLatLngNr,
  selectedCategory,
  handleSelectCategory,
  selectedSubCategory,
  handleSubCategory,
  category,
  subCategory,
  handleGst,
  gst,
  handleBusinessName,
  businessName,
  handleBillingAddress,
  billingAddress,
  handleSomeName,
  handleSomeMobile,
  someName,
  someMobile,
  services,
  handleServiceChecked,
  handleServiceQty,
  handleServiceHour,

  servicesE,
  handleServiceCheckedE,
  handleServiceQtyE,
  handleServiceHourE,

  servicesP,
  handleServiceCheckedP,
  handleServiceQtyP,
  handleServiceHourP,

  someOneRadio,
  handleSomeOneRadio,
  isPodRadio,
  handleIsPosRadio,
  shootRadio,
  handleShootRadio,

  subCategoryName,
  handleSubCategoryName,
  isDraft,
  children,

  handleServiceProviderCut,
  handleServiceProviderCutT,
  isCollaboration,
  updateArea,
  area,
  dataS,
}) {
  const [openAddDiscount, setOpenAddDiscount] = useState(false);
  const [orderID, setOrderID] = useState(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <Typography sx={{ fontWeight: "500" }}>
        {isDraft ? "" : "Update"} Order
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <Box display="flex" gap="1rem" flexWrap="wrap">
            <SelectSmall
              label="City"
              name="city"
              value={selectedCities}
              onChange={handleSelectCity}
              menuItems={cities}
              defaultValue="Select city"
            />
            <DateTime
              value={dateTime}
              onChange={handleDateTime}
              minDateTime={Date.now()}
              width="14rem"
              height="3.8rem"
              label="Date - Time"
            />
            <SelectSmall
              label="Category"
              name="category"
              value={selectedCategory}
              onChange={handleSelectCategory}
              menuItems={category}
              defaultValue="Select category"
            />

            <SelectSmall
              label="Sub Category"
              name="subCategory"
              value={selectedSubCategory}
              onChange={handleSubCategory}
              menuItems={subCategory}
              defaultValue="Select Sub category"
            />

            <TextFieldSmall
              label="Subcategory name"
              placeholder="Sub category name"
              name="subcategory-name"
              value={subCategoryName}
              onChange={handleSubCategoryName}
            />
          </Box>

          <Box display="flex" gap="1.5rem" flexWrap="wrap">
            <TextFieldSmall
              label="Remark for the order"
              placeholder="Enter remark"
              name="remarkForTheOrder"
              value={remarks}
              onChange={handleRemarkName}
            />

            <RadioButton
              label="Booking for some one"
              name="booking_for_some_one"
              value={someOneRadio == 1 ? "Yes" : "No"}
              onChange={handleSomeOneRadio}
              options={["Yes", "No"]}
            />
            {someOneRadio == 1 ? (
              <Box
                width="14rem"
                height="3.8rem"
                bgcolor="#F6F6F6"
                borderRadius="6px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingY="4.2px"
                gap="4px"
              >
                <Typography width="95%" fontSize="11.8px">
                  Some one name and number
                </Typography>

                <Box width="95%" display="flex" justifyContent="space-between">
                  <TextField
                    placeholder="Enter name"
                    size="small"
                    sx={{
                      width: "49%",
                      height: "1.9rem",
                      backgroundColor: "white",
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "center",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.750rem",
                          paddingX: "9px",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="someoneNameAndNumber_name"
                    value={someName}
                    onChange={handleSomeName}
                  />
                  <TextField
                    placeholder="Enter number"
                    size="small"
                    sx={{
                      width: "49%",
                      height: "1.9rem",
                      backgroundColor: "white",
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "center",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.750rem",
                          paddingX: "9px",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="someoneNameAndNumber_number"
                    value={someMobile}
                    onChange={handleSomeMobile}
                  />
                </Box>
              </Box>
            ) : null}
            <RadioButton
              label="Permission for social"
              name="permission_for_social"
              value={isPodRadio == 1 ? "Yes" : "No"}
              onChange={handleIsPosRadio}
              options={["Yes", "No"]}
            />
            <RadioButton
              label="Shoot area"
              name="shoot_area"
              value={shootRadio == 1 ? "Outdoor" : "Indoor"}
              onChange={handleShootRadio}
              options={["Outdoor", "Indoor"]}
            />
          </Box>

          <Box display="flex" gap="1.5rem" flexWrap="wrap">
            <TextFieldSmall
              label="GST No."
              placeholder="Enter No."
              name="GST_No"
              value={gst}
              onChange={handleGst}
            />
            <TextFieldSmall
              label="Business name"
              placeholder="Enter business name"
              name="businessName"
              value={businessName}
              onChange={handleBusinessName}
            />

            <TextFieldSmall
              label="Billing Address"
              placeholder="Enter Billing address"
              name="billingAddress"
              value={billingAddress}
              onChange={handleBillingAddress}
            />
          </Box>

          <Box display="flex" gap="10px" flexWrap="wrap">
            <Box sx={{ mr: 1, my: 1 }}>
              <LocationSearchInput
                updateArea={updateArea}
                label="Address"
                address={address}
                handleAddress={handleAddress}
                updateLatLng={updateLatLng}
              />
            </Box>
            <TextFieldSmall
              label="Nr. Address"
              placeholder="Enter nr. address"
              name="nr_address"
              value={addressNr}
              onChange={handleAddressNr}
            />

            <TextFieldSmall
              label="Flat no./Floor/Society..."
              placeholder="Enter Flat no./Floor/Society..."
              name="flatNo_floor_society"
              value={addressFlat}
              onChange={handleAddressFlat}
            />
            {area?.length ?? 0 ? (
              <TextFieldSmall
                label="Area"
                placeholder="Area"
                name="Area"
                // disableds
                // callBack={}
                value={area}
              />
            ) : null}
          </Box>
        </Box>
      </Box>

      {children}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          {services.length ? (
            <>
              <Typography sx={{ fontWeight: "600", mt: 1, fontSize: "18px" }}>
                Service
              </Typography>

              <Stack
                sx={{
                  flexDirection: "row",
                  backgroundColor: "#F8AF41",
                  width: "100%",
                  height: "40px",
                  borderTopLeftRadius: "6px",
                  borderTopRightRadius: "6px",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Typography>Service Name</Typography>
                <Typography>Service Charge</Typography>
                <Typography></Typography>
                <Typography>Qty</Typography>
                <Typography>Hours</Typography>
              </Stack>

              <ServiceView
                services={services}
                handleServiceChecked={handleServiceChecked}
                handleServiceQty={handleServiceQty}
                handleServiceHour={handleServiceHour}
                isCollaboration={isCollaboration}
                handleServiceProviderCut={handleServiceProviderCut}
                handleServiceProviderCutT={handleServiceProviderCutT}
                isDraft={isDraft}
              />
            </>
          ) : null}

          {servicesE.length ? (
            <>
              <Typography sx={{ fontWeight: "600", mt: 1, fontSize: "18px" }}>
                Add ons
              </Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  backgroundColor: "#F8AF41",
                  width: "100%",
                  height: "40px",
                  borderTopLeftRadius: "6px",
                  borderTopRightRadius: "6px",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Typography>Service Name</Typography>
                <Typography>Service Charge</Typography>
                <Typography></Typography>
                <Typography>Qty</Typography>
                <Typography>Remark</Typography>
              </Stack>
              <ServiceView
                showTextField={1}
                handleServiceRemark={handleServiceRemark}
                services={servicesE}
                handleServiceChecked={handleServiceCheckedE}
                handleServiceQty={handleServiceQtyE}
                handleServiceHour={handleServiceHourE}
              />
            </>
          ) : null}

          {servicesP.length ? (
            <>
              <Typography sx={{ fontWeight: "600", mt: 1, fontSize: "18px" }}>
                Post Add ons
              </Typography>

              <Stack
                sx={{
                  flexDirection: "row",
                  backgroundColor: "#F8AF41",
                  width: "100%",
                  height: "40px",
                  borderTopLeftRadius: "6px",
                  borderTopRightRadius: "6px",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Typography>Service Name</Typography>
                <Typography>Service Charge</Typography>
                <Typography></Typography>
                <Typography></Typography>
                <Typography></Typography>
              </Stack>

              <ServiceView
                services={servicesP}
                handleServiceChecked={handleServiceCheckedP}
                handleServiceQty={handleServiceQtyP}
                handleServiceHour={handleServiceHourP}
              />
            </>
          ) : null}
        </Box>

        <Box
          sx={{
            height: "90%",
            width: "300px",
            mt: 4,
            ml: 1,
            pr: 1,
            border: "solid 1px #c0c0c0",
            p: 2,
            borderRadius: "6px",
          }}
        >
          {dataS && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                }}
              >
                {dataS.charges.map((v, i) => {
                  return (
                    <Box
                      key={i}
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "start",
                        mb: 2,
                      }}
                    >
                      {i === 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mb: 2,
                            pb: 1,
                            borderBottom: "1px solid #c0c0c0",
                          }}
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            Sub Total
                          </Typography>
                          <Typography sx={{ ml: 2 }}>
                            ₹{dataS.sub_total.toFixed(2)}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {v.description === "Discount by POD"
                            ? v.description
                            : v.name}
                        </Typography>
                        <Typography sx={{ ml: 2 }}>
                          ₹{v.amount.toFixed(2)}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle" sx={{ fontSize: "10px" }}>
                        {v.description === "Discount by POD"
                          ? v.name
                          : v.description}
                      </Typography>

                      {balance?.coin_refunded > 0 &&
                      isCoinWalletChecked &&
                      i === dataS.charges.length - 1 ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 1.5,
                            pb: 1,
                            borderBottom: "1px solid #c0c0c0",
                          }}
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            Amount deduction from Coin Wallet
                          </Typography>
                          <Typography sx={{ ml: 2 }}>
                            ₹
                            {balance.coin_refunded - dataS.total_amount >= 0
                              ? dataS.total_amount.toFixed(2)
                              : balance.coin_refunded.toFixed(2)}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  );
                })}

                <ButtonView
                  lable={
                    dataS.charges?.some(
                      (item) => item.description === "Discount by POD"
                    )
                      ? "Update Discount"
                      : "Add Discount"
                  }
                  sx={{ mt: "1rem", p: "0.5rem" }}
                  onClick={() => {
                    setOrderID(dataS.id);
                    setOpenAddDiscount(true);
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "2rem",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Total Amount
                </Typography>
                <Typography sx={{ ml: 2 }}>
                  ₹{dataS.total_amount.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {openAddDiscount ? (
        <AddOrderDiscount
          open={openAddDiscount}
          handleClose={() => setOpenAddDiscount(false)}
          orderID={orderID}
          isAdded={
            dataS.charges?.some(
              (item) => item.description === "Discount by POD"
            )
              ? true
              : false
          }
          getData={() => getData()}
          subTotal={dataS?.sub_total}
        />
      ) : null}
    </Box>
  );
}

function ServiceView({
  services,
  handleServiceChecked,
  handleServiceHour,
  handleServiceQty,
  showTextField,
  handleServiceRemark,
  handleServiceProviderCut,
  isCollaboration,
  handleServiceProviderCutT,
  isDraft,
}) {
  
  return (
    <Box display="flex" flexDirection="column">
      {services.map((v, i) => {
        
        return (
          <ServiceItemView
            key={v.id}
            data={v}
            i={i}
            handleServiceChecked={handleServiceChecked}
            handleServiceQty={handleServiceQty}
            handleServiceHour={handleServiceHour}
            handleServiceRemark={handleServiceRemark}
            showTextField={showTextField}
            handleServiceProviderCut={handleServiceProviderCut}
            isCollaboration={isCollaboration}
            handleServiceProviderCutT={handleServiceProviderCutT}
            isDraft={isDraft}
          />
        );
      })}
    </Box>
  );
}
function ServiceItemView({
  data,
  i,
  handleServiceChecked,
  handleServiceHour,
  handleServiceQty,
  showTextField,
  handleServiceRemark,
  handleServiceProviderCut,
  handleServiceProviderCutT,
  isCollaboration,
  isDraft,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        border: "solid 1px",
        borderColor: "divider",
        p: 3,
        pb: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#F6F6F6",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "5px",
            width: "200px",
            alignItems: "flex-start",
          }}
        >
          <Typography sx={{ flex: 1 }} variant="body1" fontWeight="500">
            {data.name}
          </Typography>
          {data.status == 0 ? (
            <Chip
              label="Inactive"
              size="small"
              sx={{
                backgroundColor: "#e500001f",
                width: "57px",
                fontSize: "10px",
                height: "15px",
                fontWeight: "600",
              }}
            />
          ) : null}
        </Box>
        <Typography
          sx={{ flex: 1, ml: 2, textAlign: "center" }}
          variant="subtitle1"
        >
          ₹{data.price}
        </Typography>

        <Box
          sx={{
            mt: -1,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            justifyContent: "flex-end",
          }}
        >
          <ButtonForGrid
            bgcolor={data.checked ? "#E5000033" : "#FFD393"}
            label={data.checked ? "Remove Service" : "Add Service"}
            onClick={(e) => handleServiceChecked(!data.checked, i)}
          />
          {/* <Switch
            checked={data.checked ?? false}
            onChange={(e) => handleServiceChecked(e.target.checked, i)}
          /> */}
        </Box>

        {data.isQty && data.checked ? (
          <PriceHour
            counter={data.qty}
            index={i}
            isHour={0}
            handleCounter={(value, i) => handleServiceQty(value, i)}
          />
        ) : (
          <Box sx={{ flex: 1 }} />
        )}
        {data.isPerHour && data.checked ? (
          <PriceHour
            counter={data.hour}
            index={i}
            isHour={1}
            handleCounter={(value, i) => handleServiceHour(value, i)}
          />
        ) : (
          !showTextField && <Box sx={{ flex: 1 }} />
        )}

        {showTextField && (
          <TextField
            variant="outlined"
            size="small"
            placeholder="Remark"
            sx={{
              flex: 1,
              width: "70px",
              ml: 2,
            }}
            lable="Remark"
            value={data?.remarks}
            onChange={(e) => {
              console.log('e.target: ' + e.target.value);
              handleServiceRemark(e.target.value, i)
            }}
          />
        )}
      </Box>

      {isCollaboration && (
        <Box
          style={{
            display: "flex",
            gap: 5,
            mt: 2,
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              Providers Cut (%)
            </Typography>
            <TextFieldViewN
              disabled={
                data.provider_cut >= 0 && data.provider_cut != null && !isDraft
              }
              lable="Provider cut %"
              value={data.cut}
              callBack={(e) => handleServiceProviderCut(e.target.value, i)}
            />
          </Box>
          {data.provider_cut >= 0 &&
          data.provider_cut != null &&
          !isDraft ? null : (
            <Button
              variant="outlined"
              sx={{
                width: "50px",
                textAlign: "center",
                height: "30px",
                mt: 2,
              }}
              onClick={() => handleServiceProviderCutT()}
            >
              Submit
            </Button>
          )}
        </Box>
      )}
      {data.discounts?.length ? (
        <Box>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box>
              {data.discounts?.map((v) => (
                <Box
                  key={v.id}
                  sx={{
                    p: 1,
                    borderBottom: "solid 1px #c0c0c0",
                  }}
                >
                  <Typography>
                    {v.isPercentage
                      ? `${
                          v.on +
                          "% (max " +
                          v.maxAmount +
                          ") off on " +
                          v.apply +
                          (v.type == 1 ? " hours" : " amount")
                        }`
                      : `
                    ${
                      v.on +
                      " off on " +
                      v.apply +
                      (v.type == 1 ? " hours" : " amount")
                    }
                    `}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Popover>

          <Box
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{
              width: "60px",
              alignSelf: "flex-end",
              mt: 1.5,
              backgroundColor: "#f88181",
              borderRadius: "25px",
              textAlign: "center",
            }}
          >
            <Typography sx={{ color: "#fff" }}>Offer</Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
// function TextFieldView({
//   lable,
//   value,
//   callBack,
//   size = "small",
//   disabled = false,
// }) {
//   return (
//     <TextField
//       focused
//       size={size}
//       sx={{ mr: 1, mt: 1 }}
//       label={lable}
//       value={value}
//       onChange={callBack}
//       disabled={disabled}
//     />
//   );
// }
function TextFieldViewN({
  lable,
  value,
  callBack,
  size = "small",
  disabled = false,
}) {
  return (
    <TextField
      type="number"
      focused
      size={size}
      sx={{ mr: 1 }}
      // label={lable}
      value={value}
      onChange={callBack}
      disabled={disabled}
    />
  );
}
